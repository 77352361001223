import React, { useEffect, useContext, useState } from "react";
import ProfileNavbar from "../../layout/ProfileNavbar";
import FixedHeader from "../../layout/FixedHeader";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { MainContent } from "../../App";
import { url, errorHandler } from "../../api/Api";
import Cookies from "js-cookie";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import SubscriptionType from "./SubscriptionType";
import paypal from "../../images/paypal.png";
import stripe from "../../images/stripe.png";

import {
  useGetPaymentAmount,
  useGetPaymentData,
} from "../../hooks/CustomHooks";
const currencyArray = [
  { translistid: 4, name: "DKK" },
  { translistid: 1, name: "EUR" },
  { translistid: 3, name: "GBP" },
  { translistid: 5, name: "NOK" },
  { translistid: 6, name: "SEK" },
  { translistid: 2, name: "USD" },
];

const Payment = () => {
  const { t } = useTranslation();
  const { changnow, language, myown_data } = useContext(MainContent);
  const { data: paymentData } = useGetPaymentData(language);
  const {
    paymentmethod: paymentMethod,
    currencytype: currencyType,
    paymentperiodtype: paymentPeriodType,
  } = paymentData || {};
  const [cardid, setCardid] = useState(1);
  const myid = myown_data?.data?.profileid || "";
  const [basicpagetext, setBasicpagetext] = useState("");
  const [goldpagetext, setGoldpagetext] = useState("");
  const [platinumpagetext, setPlatinumpagetext] = useState("");
  const [sucss, setSucss] = useState(true);
  const [msg, setMsg] = useState("");
  const [paymentpage, setPaymentpage] = useState("");
  const [currencyId, setCurrencyId] = useState(
    myown_data?.data?.profileattributes?.defaultcurrency || 2
  );
  const [subscriptionTypeId, setSubscriptionTypeId] = useState(2);
  const [subscriptionType, setSubscriptionType] = useState("");
  const [subscriptionPaymentType, setSubscriptionPaymentType] = useState("");
  const [timePeriodId, setTimePeriodId] = useState(1);
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  const getbasicpage = () => {
    let myHeaders = new Headers();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}sitepage/pagetype?PageTypeId=16&LangCode=${language}`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.data) {
          setBasicpagetext(result.data.text);
        }
      });
  };

  const getgoldpage = () => {
    let myHeaders = new Headers();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}sitepage/pagetype?PageTypeId=17&LangCode=${language}`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.data) {
          setGoldpagetext(result.data.text);
        }
      });
  };

  const getPaymentPage = () => {
    let myHeaders = new Headers();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}sitepage/pagetype?PageTypeId=10&LangCode=${language}`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.data) {
          setPaymentpage(result.data.text);
        }
      });
  };

  const getplatinumpage = () => {
    let myHeaders = new Headers();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}sitepage/pagetype?PageTypeId=18&LangCode=${language}`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.data) {
          setPlatinumpagetext(result.data.text);
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPaymentPage();
    getgoldpage();
    getplatinumpage();
    getbasicpage();
  }, [changnow, language]);

  const { data: amount } = useGetPaymentAmount(language, currencyId);

  const filterdata = amount?.paymentsubscriptiontype?.filter(
    (item) =>
      item?.paymentperiodid === timePeriodId &&
      item?.subscriptiontypeid === subscriptionTypeId
  );
  const price = filterdata ? filterdata[0]?.price : null;

  const paypalPay = () => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    const raw = JSON.stringify({
      amount: price,
      paymentPeriodId: timePeriodId,
      profileId: myid,
      subscriptionTypeId: subscriptionTypeId,
      paymentCurrencyId: currencyId,
      paymentStatusId: 1,
      paymentMethodId: cardid,
      note: "Your comment",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}Payment/add`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        // Clean the result to ensure it's a valid URL
        if (result.startsWith('"') && result.endsWith('"')) {
          result = result.slice(1, -1); // Remove surrounding quotes
        }

        // Redirect to the cleaned URL
        window.location.href = result;
      })
      .catch((error) => {
        alert("Error during fetch or redirect:", error);
      });
  };

  const stripPay = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Accept-Language", language || "en");

      const raw = JSON.stringify({
        amount: price,
        paymentPeriodId: timePeriodId,
        profileId: myid,
        subscriptionTypeId: subscriptionTypeId,
        paymentCurrencyId: currencyId,
        paymentStatusId: 1,
        paymentMethodId: cardid,
        note: "Your comment",
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(`${url}Payment/add`, requestOptions);
      const result = await response.json();
      if (response.ok) {
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
        const { error } = await stripe.redirectToCheckout({
          sessionId: result,
        });

        if (error) {
          console.error(error);
        }
      } else {
        console.error("Error:", result.error || "Unknown error occurred");
        // Handle error appropriately, e.g., show an error message to the user
      }
    } catch (error) {
      console.error("Unexpected error:", error);
      // Handle unexpected errors appropriately
    }
  };

  const validPromo = (text) => {
    if (text !== "") {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${url}promotion/validate?PromotionCode=${text}`, requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          setSucss(result.success);
          setMsg(result.message);
        });
    } else {
      setMsg("");
    }
  };

  const currencyName = currencyArray?.find(
    (item) => item.translistid === currencyId
  )?.name;

  const validCardIds = ["1", 1];

  // Function to format currency
  const formatCurrency = (amount, locale, currency) => {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    }).format(amount);
  };

  // Function to get price based on payment period ID
  const getPriceByPaymenPeriodtId = (paymentPeriodId) => {
    const subscription = amount?.paymentsubscriptiontype.find(
      (sub) =>
        sub.paymentperiodid === paymentPeriodId &&
        sub.subscriptiontypeid === subscriptionTypeId
    );

    if (subscription) {
      // Format the price using the formatCurrency function and append the payment period type in a bold tag
      return (
        <>
          {formatCurrency(subscription.price, language, currencyName)}{" "}
          <b>{subscription.paymentperiodtype}</b>
        </>
      );
    }

    // Return null if no subscription is found
    return null;
  };

  const handleButtonClick = (id) => {
    setTimePeriodId(id);
  };

  useEffect(() => {
    const subscription = amount?.paymentsubscriptiontype.find(
      (sub) =>
        sub.paymentperiodid === timePeriodId &&
        sub.subscriptiontypeid === subscriptionTypeId
    );

    if (subscription) {
      setSubscriptionPaymentType(subscription.paymentperiodtype);
      setSubscriptionType(subscription.subscriptiontype);
    }
  }, [timePeriodId, subscriptionTypeId, language, amount]);

  return (
    <div className=" bg-blue-50">
      <div className="container mt-16 lg:pt-10 min-h-screen pb-5">
        <div className="container">
          <div className="mb-2">
            <ProfileNavbar />
          </div>
          <FixedHeader
            scrollPosition={scrollPosition}
            headerText={t("Payment")}
            language={language}
          />
          <div className=" w-full flex items-center mb-5 justify-center ">
            <h1 className="text-center mt-9  text-sr font-medium sm:text-xl md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
              <BsBlockquoteLeft className="text-sr" />
              {t("Payment")}
              <BsBlockquoteRight className="text-sr" />
            </h1>
          </div>
          <div className="mt-2">
            <div
              className="bg-white py-5 px-5 w-full shadow-md rounded-lg font-normal"
              dangerouslySetInnerHTML={{ __html: paymentpage }}
            ></div>
            <div className=" bg-white shadow-md rounded-md p-3 my-3">
              <div className="flex items-start justify-start p-4 w-full font-extrabold text-2xl">
                {t("SubscriptionPlan")}
              </div>

              <div className="flex items-center gap-3 py-3">
                <button
                  onClick={() => {
                    setSubscriptionTypeId(1);
                  }}
                  className={
                    subscriptionTypeId === 1
                      ? "px-3 py-2 text-xs rounded-md font-medium text-white border bg-sr"
                      : "px-3 py-2 text-xs rounded-md font-medium text-sr border hover:bg-sr hover:text-white"
                  }
                >
                  {t("Basic")}
                </button>
                <button
                  onClick={() => {
                    setSubscriptionTypeId(2);
                  }}
                  className={
                    subscriptionTypeId === 2
                      ? "px-3 py-2 text-xs rounded-md font-medium text-white border bg-sr"
                      : "px-3 py-2 text-xs rounded-md font-medium text-sr border hover:bg-sr hover:text-white"
                  }
                >
                  {t("Gold")}
                </button>
                <button
                  onClick={() => {
                    setSubscriptionTypeId(3);
                  }}
                  className={
                    subscriptionTypeId === 3
                      ? "px-3 py-2 text-xs rounded-md text-white border bg-sr"
                      : "px-3 py-2 text-xs rounded-md text-sr font-medium border hover:bg-sr hover:text-white"
                  }
                >
                  {t("Platinum")}
                </button>
              </div>
              {subscriptionTypeId !== 1 ? (
                <>
                  <div className="">
                    {" "}
                    {subscriptionTypeId === 2 ? (
                      <div
                        className="bg-blue-200 py-5 px-5 w-full shadow-md rounded-lg font-normal"
                        dangerouslySetInnerHTML={{ __html: goldpagetext }}
                      ></div>
                    ) : (
                      <div
                        className="bg-blue-200 py-5 px-5 w-full shadow-md rounded-lg font-normal"
                        dangerouslySetInnerHTML={{
                          __html: platinumpagetext,
                        }}
                      ></div>
                    )}
                  </div>
                  <div className="items-center gap-6 ">
                    <div className="gap-1">
                      <div className=" w-full flex pt-3">
                        <h6 className="font-medium text-sr pt-1">
                          {t("Payment_Period")}
                        </h6>
                        <div className="pt-0 flex items-center justify-center lg:justify-start gap-3 flex-wrap">
                          <label
                            htmlFor="seeking"
                            className="form-label flex items-center text-base font-medium text-pr"
                          >
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="button-tooltip">
                                  {t("Select_Payment_Period_From_List")}
                                </Tooltip>
                              }
                            >
                              <div className="h-10 w-10">
                                <AiOutlineQuestionCircle className="ml-3 p-1 w-7 h-7 text-pr cursor-pointer" />
                              </div>
                            </OverlayTrigger>
                          </label>
                        </div>
                      </div>
                      <div className="flex-wrap items-center gap-1 w-full">
                        <div className="mt-0 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-2">
                          {paymentPeriodType?.map((item) => (
                            <div
                              key={item.translistid}
                              onClick={() =>
                                handleButtonClick(item.translistid)
                              }
                              className={`option cursor-pointer border rounded-lg p-4 
    ${
      timePeriodId === item.translistid
        ? "bg-blue-300 text-white"
        : "bg-gray-200 text-black"
    } 
    hover:bg-blue-300 hover:text-white hover:scale-105 hover:z-10 transition-all`}
                            >
                              <h3 class="text-lg font-semibold text-gray-700">
                                {item.name}{" "}
                              </h3>
                              <p class="text-gray-600">
                                {getPriceByPaymenPeriodtId(item.translistid)}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="flex  pt-2 items-center">
                        <h6 className="font-medium text-sr mr-4">
                          {t("Payment_Method")}
                        </h6>
                        <div className="flex items-center gap-5">
                          <img src={paypal} className="h-12" alt="" />
                          <img src={stripe} className="h-12" alt="" />
                        </div>
                      </div>
                      <div className="flex items-center gap-3">
                        <Form.Select
                          className=" shadow-md font-medium text-sr text-sm"
                          onChange={(e) => setCardid(e.target.value)}
                        >
                          {paymentMethod?.map((item, ind) => (
                            <option key={ind} value={item.translistid}>
                              {item.name}
                            </option>
                          ))}
                        </Form.Select>
                        <div className="pt-0 flex items-center justify-center lg:justify-start gap-3 flex-wrap">
                          <label
                            htmlFor="seeking"
                            className="form-label flex items-center text-base font-medium text-pr"
                          >
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="button-tooltip">
                                  {t("Select_Payment_Method_From_List")}
                                </Tooltip>
                              }
                            >
                              <div className="h-10 w-10">
                                <AiOutlineQuestionCircle className="ml-3 p-1 w-7 h-7 text-pr cursor-pointer" />
                              </div>
                            </OverlayTrigger>
                          </label>
                        </div>
                      </div>

                      <div className="flex mt-2 ">
                        <h6 className="font-medium text-sr">
                          {t("Currency_Type")}
                        </h6>
                      </div>
                      <div className="flex items-center gap-3">
                        <Form.Select
                          value={currencyId}
                          className=" shadow-md font-medium text-sr text-sm"
                          onChange={(e) => {
                            setCurrencyId(parseInt(e.target.value));
                          }}
                        >
                          {currencyType?.map((item, index) => (
                            <option key={index} value={item.translistid}>
                              {item.name}
                            </option>
                          ))}
                        </Form.Select>
                        <div className="pt-4 flex items-center justify-center lg:justify-start gap-3 flex-wrap">
                          <label
                            htmlFor="seeking"
                            className="form-label flex items-center text-base font-medium text-pr"
                          >
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="button-tooltip">
                                  {t("Select_Currency_Type_From_List")}
                                </Tooltip>
                              }
                            >
                              <div className="h-10 w-10">
                                <AiOutlineQuestionCircle className="ml-3 p-1 w-7 h-7 text-pr cursor-pointer" />
                              </div>
                            </OverlayTrigger>
                          </label>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center gap-2">
                          <h6 className="font-medium text-sr">
                            {t("Promo_Code")}
                          </h6>
                          <label
                            htmlFor="Promo_Code"
                            className="form-label flex items-center text-base font-medium text-pr"
                          >
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="button-tooltip">
                                  {t("Promo_Code_Tooltip")}
                                </Tooltip>
                              }
                            >
                              <div className="h-10 w-10 pt-2">
                                <AiOutlineQuestionCircle className="ml-3 p-1 w-7 h-7 text-pr cursor-pointer" />
                              </div>
                            </OverlayTrigger>
                          </label>
                        </div>
                        <div>
                          <Form.Control
                            type="text"
                            className=" shadow-md font-medium text-sr text-sm w-60"
                            onBlur={(e) => validPromo(e.target.value)}
                            placeholder={t("Promo_Code")}
                          />
                        </div>
                        {sucss ? null : (
                          <p className="text-xs text-red-500 pt-2">{msg}</p>
                        )}
                      </div>
                    </div>
                    <div className="">
                      <div className="w-full flex items-center justify-center lg:justify-end mt-4  mb-4">
                        <div className="flex items-center gap-2 flex-col mt-2">
                          {validCardIds.includes(cardid) ? (
                            <button
                              style={{ width: "260px" }}
                              onClick={paypalPay}
                              className="flex flex-col items-center  px-4 py-2 rounded-md bg-sr text-white shadow-md transition duration-300 ease-linear "
                            >
                              <span className="text-xl">
                                {t("ProceedToPay")}
                              </span>
                              <span className="text-pr text-xl font-extrabold ">
                                {formatCurrency(price, language, currencyName)}
                              </span>
                              <span className="text-sm">
                                {subscriptionType} / {subscriptionPaymentType}
                              </span>
                              {/* <span className="text-sm">
                                {subscriptionPaymentType}
                              </span> */}
                            </button>
                          ) : (
                            <button
                              style={{ width: "260px" }}
                              onClick={stripPay}
                              className="flex flex-col items-center px-4 py-2 rounded-md bg-sr text-white shadow-md transition duration-300 ease-linear"
                            >
                              <span className="text-xl">
                                {t("ProceedToPay")}
                              </span>
                              <span className="text-pr text-xl font-extrabold">
                                {formatCurrency(price, language, currencyName)}
                              </span>
                              <span className="text-sm">
                                {subscriptionType}/{subscriptionPaymentType}
                              </span>
                              {/* <span className="text-sm">
                                {subscriptionPaymentType}
                              </span> */}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className="bg-blue-200 py-5 px-5 w-full shadow-md rounded-lg font-medium"
                  dangerouslySetInnerHTML={{ __html: basicpagetext }}
                ></div>
              )}
            </div>
          </div>
          <SubscriptionType />
        </div>
      </div>
    </div>
  );
};

export default Payment;

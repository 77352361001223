import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from "js-cookie";
import React, { createContext, lazy, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Admin from "./components/Admin/Admin";
import Activity from "./components/profile/Activity";
import Dashboard from "./components/profile/Dashboard";
import WelcomePage from "./components/profile/WelcomePage";
import Matches from "./components/profile/Matches";
import Search from "./components/profile/Search";
import PrivateProfile from "./components/profileSettings/PrivateProfile";
import ImageAccess from "./components/profileSettings/pSettingsPages/ImageAccess";
import ProfileDelete from "./components/profileSettings/pSettingsPages/ProfileDelete";
import ProfileEdit from "./components/profileSettings/pSettingsPages/ProfileEdit";
import ProfilePhotos from "./components/profileSettings/pSettingsPages/ProfilePhotos";
import PublicProfile from "./components/profileSettings/PublicProfile";
import MembersSettings from "./components/userSettings/membersettings/MemberSettings";
import Payment from "./components/userSettings/Payment";
import PaymentCheck from "./components/userSettings/PaymentCheck";
import Footer from "./layout/Footer";
import Navbar from "./layout/Navbar";
import ChangeEmailChange from "./components/userSettings/membersettings/ChangeEmailChange";
import PaymentSuccess from "./components/userSettings/PaymentSuccess";
import PaymentWrong from "./components/userSettings/PaymentWrong";
import Notfound from "./components/Notfound";
import PaymentCancel from "./components/PaymentCancel";
import Checkonline from "./components/Checkonline";
import SomethingWrong from "./components/SomethingWrong";
import TooManyRequests from "./components/TooManyRequests";
import ProfileNotFound from "./components/ProfileNotFOund";
import SuccessStoryPage from "./components/publicpage/SuccessStoryPage";
import PublicPage from "./components/publicpage/PublicPage";
import { errorHandler } from "./api/Api";
import GDPR from "./layout/GDPR";
import Confirmemail from "./authentication/Confirmemail";
import Forgotpassmsg from "./authentication/ForgotPassMsg";
import Spinner from "./components/Spinner";
import ContactReceipt from "./components/userSettings/membersettings/ContactReceipt";
import PublicContact from "./components/publicpage/PublicContact";
import BlogList from "./components/publicpage/BlogList";
import Blog from "./components/publicpage/Blog";
import ConfirmEmailMsg from "./authentication/ConfirmEmailMsg";
import Loader from "./components/Loader";
import SiteStartLanguage from "./components/SiteStartLanguage";
import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from "./layout/Sidebar";
import ChangeEmailReceipt from "./components/userSettings/ChangeEmailReceipt";
import ChagePassReceipt from "./components/userSettings/ChangePasswordReceipt";
import FAQ from "./components/publicpage/FAQ";
import { toast } from "react-toastify";
import endpoints, { HANDLE_API_REQUEST } from "./api/EndPoints";
import Chat from "./components/chat/Chat";
import { socketConnection } from "./utils/socketUtils";
import StartPage from "./components/profile/StartPage";
import ErrorBoundary from "./components/ErrorBoundary";
import OneSignal from "react-onesignal";
import { customToasterStyle } from "./utils/constants";
import PrivateContact from "./components/userSettings/membersettings/PrivateContact";
export const MainContent = createContext();

const Register = lazy(() => import("./components/home/Home"));
const VerifyConfirmEmail = lazy(() =>
  import("./authentication/VerifyConfirmEmail")
);

const Resetpassword = lazy(() => import("./authentication/Resetpassword"));

function App() {
  const { t } = useTranslation();
  const [grid, setGrid] = useState(true);
  const [checkleft, setCheckleft] = useState(true);
  const [isAuthenticated, setisAuthenticated] = useState(false);
  const [changnow, setChangnow] = useState();
  const [updateMailbadge, setUpdateMailbadge] = useState(false);
  const [advancesho, setAdvancesho] = useState(true);
  const [changeimage, setChangeimage] = useState(false);
  const [show, setShow] = useState(true);
  const [checkResponse, setCheckResponse] = useState(null);
  const [footerShow, setFooterShow] = useState(true);
  const [openReg, setOpenReg] = useState(false);
  const { i18n } = useTranslation();
  const [myown_data, setMyown_data] = useState(null);
  const [language, setLanguage] = useState("en");
  const [basicshow, setBasicshow] = useState(true);
  const [isProfileImagesPublic, setIsProfileImagesPublic] = useState(true);
  const [isMemberPlatinum, setIsMemberPlatinum] = useState(false);
  const [open, setOpen] = useState(false);
  const [freshReload, setFreshReload] = useState(false); // Updates current userdata
  const [isCurrentProfileApproved, setIsCurrentProfileApproved] =
    useState(false);
  const [isCurrentProfileAvailable, setIsCurrentProfileAvailable] =
    useState(false);
  const [isMarkedForDeletion, setIsMarkedForDeletion] = useState(false);
  const [ownerEmail, setOwnerEmail] = useState("");
  const [newMessage, setNewMessage] = useState(0);
  const [pushNotificationEnabled, setPushNotificationEnabled] = useState(false);
  const [currentGender, setCurrentGender] = useState(null);
  const [isMailNotificationInitialized, setIsMailNotificationInitialized] =
    useState(false);
  const showtoast = (result, successText, errorText) => {
    if (result.success) {
      toast.success(t(successText || "Toast_Successfully_Saved"), {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    } else {
      toast.error(t(errorText || "Toast_Saving_Unsuccessful"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    }
  };

  const changelanguage = (e) => {
    // if (language === "ar") {
    //   changeLanguagrToArabic();
    // }

    i18n.changeLanguage(e);
  };
  const handleSetSiteSettings = ({
    defaultlanguage,
    defaultstartuppage,
    defaultcurrency,
  }) => {
    let data = { ...myown_data };
    data.data.profileattributes.defaultstartuppage = defaultstartuppage;
    data.data.profileattributes.defaultcurrency = defaultcurrency;
    data.data.profileattributes.defaultlanguage = defaultlanguage;
    setMyown_data(data);
    setIsProfileImagesPublic(data?.data?.albumcontainer?.ispublic ?? true);
    setLanguage(defaultlanguage);
    defaultlanguage !== "ar"
      ? changeDirectionsame(defaultlanguage)
      : changeDirection(defaultlanguage);
    localStorage.setItem("i18nextLng", defaultlanguage);
  };

  const changeLanguagrToArabic = () => {
    const rootElement = document.getElementById("root");
    rootElement.style.cssText = `
    direction: rtl;
    font-family: 'Noto Kufi Arabic', sans-serif;
    font-size: 15px;
  `;
  };
  const changeDirection = (e) => {
    if (e === "ar") {
      changelanguage(e);
      changeLanguagrToArabic();
      setCheckleft(false);
      setLanguage(e);
    }
  };

  const changeDirectionsame = (e) => {
    if (e !== "ar") {
      changelanguage(e);
      document.getElementById("root").style.direction = "ltr";
      document.getElementById("root").style.fontFamily = "Roboto, sans-serif";
      document.getElementById("root").style.fontSize = "15px";
      setCheckleft(true);
      setLanguage(e);
    }
  };

  const getOwnData = () => {
    HANDLE_API_REQUEST.GET(endpoints.GET_OWN_DATA, language)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setMyown_data(result);
        setIsProfileImagesPublic(
          result?.data?.albumcontainer?.ispublic ?? true
        );
        setIsCurrentProfileApproved(result.data?.profileattributes.approved);
        setIsCurrentProfileAvailable(result.data?.profileattributes.available);
        setIsMemberPlatinum(
          result.data?.profileattributes.subscriptiontype === "Platinum"
        );
        setIsMarkedForDeletion(
          result.data?.profileattributes.markedfordeletion
        );
        setOwnerEmail(result.data?.profileattributes.email);
        setPushNotificationEnabled(
          result.data?.profileattributes.pushnotificationenabled
        );
        setCurrentGender(result.data?.profileattributes.gender);
        const lang = localStorage.getItem("i18nextLng");

        if (result.data.profileattributes) {
          if (lang !== null && lang !== undefined) {
            changelanguage(lang);
          } else if (
            result.data.profileattributes.defaultlanguage !== null &&
            result.data.profileattributes.defaultlanguage !== undefined
          ) {
            changelanguage(result.data.profileattributes.defaultlanguage);
          } else {
            changelanguage("en");
          }
        }
      });
  };

  useEffect(() => {
    if (freshReload === true) {
      getOwnData();
      setFreshReload(false);
    }
  }, [freshReload, language]);

  const refreshTime = process.env.REACT_APP_REFRESH_TIME;

  const [handleReload, setHandleReload] = useState(false);

  useEffect(() => {
    const lang = localStorage.getItem("i18nextLng") || "en";

    if (lang === "ar") {
      changelanguage("ar");
      setLanguage("ar");
      setCheckleft(false);
      // document.getElementById("root").style.direction = "rtl";
      // document.getElementById("root").style.fontFamily =
      //   "Noto Kufi Arabic, sans-serif;";
      // document.getElementById("root").style.fontSize = "15px";
      changeLanguagrToArabic();
    }

    if (lang !== "ar") {
      changelanguage(lang);
      setLanguage(lang);
      document.getElementById("root").style.fontFamily = "Roboto, sans-serif";
      document.getElementById("root").style.fontSize = "15px";
    }

    const declineCookie = Cookies.get("decline");
    const acceptCookie = Cookies.get("accept");

    if (declineCookie || acceptCookie) {
      setShow(false);
    }
  }, []);

  useEffect(() => {
    if (myown_data) {
      //Used by SignalR
      if (myown_data !== null && myown_data !== undefined) {
        const oneMonthFromNow = new Date();
        oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);
        Cookies.set("userId", myown_data.data.profileid, {
          expires: oneMonthFromNow,
        });
      }
    }
  }, [myown_data]);

  // check if accesstoken valid
  useEffect(() => {
    let timeoutId;
    if (Cookies.get("accessToken") && Cookies.get("refreshToken")) {
      const token = Cookies.get("accessToken");
      const refreshToken = Cookies.get("refreshToken");
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("ApiKey", process.env.REACT_APP_API_KEY);
      myHeaders.append("Accept-Language", language || "en");

      const body = JSON.stringify({
        token: token,
        refreshToken: refreshToken,
      });

      const config = {
        method: "POST",
        headers: myHeaders,
        body: body,
        redirect: "follow",
      };
      HANDLE_API_REQUEST.POST(endpoints.REFRESH_TOKEN, null, config)
        .then((response) => {
          setCheckResponse(response);
          return response.json();
        })
        .then((result) => {
          if (result.succeeded) {
            // Set cookies to expire after one month
            const oneMonthFromNow = new Date();
            oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);

            Cookies.set("accessToken", result.token, {
              expires: oneMonthFromNow,
            });
            Cookies.set("refreshToken", result.refreshtoken, {
              expires: oneMonthFromNow,
            });
            Cookies.set("isAuthenticated", result.succeeded, {
              expires: oneMonthFromNow,
            });
            setChangnow(result.token);
            getOwnData();
            setisAuthenticated(result.succeeded);
            localStorage.setItem("isAuthenticated", true);
            timeoutId = setTimeout(() => {
              setHandleReload(!handleReload);
            }, refreshTime);
          } else {
            setisAuthenticated(false);
            Cookies.set("isAuthenticated", false);
          }
        });
    } else {
      setisAuthenticated(false);
      Cookies.set("isAuthenticated", false);
      setCheckResponse(true);
    }

    if (isAuthenticated) {
      const signalrWebsocket = socketConnection();
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isAuthenticated, handleReload]);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  const deleteOneSignalPushNotification = async (token) => {
    try {
      const body = JSON.stringify({
        fcmtoken: token,
      });

      const response = await HANDLE_API_REQUEST.DELETE(
        endpoints.DELETE_ONESIGNAL_PUSHNOTIFICATION,
        body
      );
      errorHandler(response);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleOneSignalPushNotification = async (
    token,
    platform,
    subscriptionid
  ) => {
    try {
      const body = JSON.stringify({
        fcmtoken: token,
        platform: platform,
        subscriptionid: subscriptionid,
      });

      const response = await HANDLE_API_REQUEST.POST(
        endpoints.SAVE_ONESIGNAL_PUSHNOTIFICATION,
        body
      );
      errorHandler(response);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const initializeOneSignal = async () => {
      if (isAuthenticated) {
        const translations = {
          // Arabic
          ar: {
            actionMessage:
              "اشترك في إشعاراتنا للحصول على آخر الأخبار والتحديثات. يمكنك إيقافها في أي وقت.",
            acceptButton: "اشترك",
            cancelButton: "لا، شكرًا",
          },
          // Italien
          it: {
            actionMessage:
              "Iscriviti alle nostre notifiche per le ultime notizie e aggiornamenti. Puoi disattivarle in qualsiasi momento.",
            acceptButton: "Iscriviti",
            cancelButton: "No, grazie",
          },
          // Danish
          da: {
            actionMessage:
              "Abonner på vores notifikationer for de seneste nyheder og opdateringer. Du kan deaktivere dem når som helst.",
            acceptButton: "Abonner",
            cancelButton: "Nej tak",
          },
          // Norwegian
          no: {
            actionMessage:
              "Abonner på våre varsler for de siste nyhetene og oppdateringene. Du kan deaktivere dem når som helst.",
            acceptButton: "Abonner",
            cancelButton: "Nei takk",
          },
          // Sweedish
          sv: {
            actionMessage:
              "Prenumerera på våra aviseringar för de senaste nyheterna och uppdateringarna. Du kan inaktivera dem när som helst.",
            acceptButton: "Prenumerera",
            cancelButton: "Nej tack",
          },
          // Turkish
          tr: {
            actionMessage:
              "Son haberleri ve güncellemeleri almak için bildirimlerimize abone olun. İstediğiniz zaman devre dışı bırakabilirsiniz",
            acceptButton: "Abone Ol",
            cancelButton: "Hayır teşekkürler",
          },
          // Dutch
          nl: {
            actionMessage:
              "Abonneer je op onze meldingen voor het laatste nieuws en updates. Je kunt ze op elk moment uitschakelen.",
            acceptButton: "Abonneren",
            cancelButton: "Nee, bedankt",
          },
          // German
          de: {
            actionMessage:
              "Abonnieren Sie unsere Benachrichtigungen für die neuesten Nachrichten und Updates. Sie können sie jederzeit deaktivieren.",
            acceptButton: "Abonnieren",
            cancelButton: "Nein danke",
          },
          // French
          fr: {
            actionMessage:
              "Abonnez-vous à nos notifications pour les dernières nouvelles et mises à jour. Vous pouvez désactiver à tout moment.",
            acceptButton: "S'abonner",
            cancelButton: "Non merci",
          },
          // Spanish
          es: {
            actionMessage:
              "Suscríbete a nuestras notificaciones para recibir las últimas noticias y actualizaciones. Puedes desactivarlo en cualquier momento.",
            acceptButton: "Suscribir",
            cancelButton: "No, gracias",
          },
          default: {
            actionMessage:
              "Subscribe to our notifications for the latest news and updates. You can disable anytime.",
            acceptButton: "Subscribe",
            cancelButton: "No thanks",
          },
        };

        //var language = navigator.language.toLowerCase();
        let translation = translations[language] || translations.default;
        let promptLangs = { ...translation };

        await OneSignal.init({
          appId: process.env.REACT_APP_ONESIGNAL_KEY,
          promptOptions: {
            slidedown: {
              prompts: [
                {
                  type: "push",
                  autoPrompt: true,
                  text: { ...promptLangs },
                },
              ],
            },
          },
        });
      }
    };

    initializeOneSignal();
  }, [isAuthenticated]);

  useEffect(() => {
    const initializeOneSignal = async () => {
      try {
        const subscriptionId = OneSignal.User.PushSubscription.id;
        const isSupported = OneSignal.Notifications.isPushSupported();
        let permission = OneSignal.Notifications.permission;

        if (
          subscriptionId &&
          isSupported &&
          permission &&
          pushNotificationEnabled
        ) {
          OneSignal.User.addAlias("external_id", subscriptionId);
          await handleOneSignalPushNotification(
            subscriptionId,
            "Web",
            subscriptionId
          );
        }
        if (!pushNotificationEnabled && subscriptionId) {
          await deleteOneSignalPushNotification(subscriptionId);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };
    initializeOneSignal();
  }, [pushNotificationEnabled]);

  return (
    <MainContent.Provider
      value={{
        handleSetSiteSettings,
        showtoast,
        setisAuthenticated,
        checkleft,
        setGrid,
        grid,
        changeDirection,
        changeDirectionsame,
        language,
        changnow,
        changeimage,
        setChangeimage,
        isAuthenticated,
        setShow,
        show,
        myown_data,
        basicshow,
        setBasicshow,
        setFooterShow,
        setLanguage,
        advancesho,
        setAdvancesho,
        open,
        setOpen,
        openReg,
        setOpenReg,
        setFreshReload,
        updateMailbadge,
        setUpdateMailbadge,
        setMyown_data,
        isProfileImagesPublic,
        isCurrentProfileApproved,
        isCurrentProfileAvailable,
        isMarkedForDeletion,
        ownerEmail,
        newMessage,
        setNewMessage,
        isMemberPlatinum,
        setPushNotificationEnabled,
        isMailNotificationInitialized,
        setIsMailNotificationInitialized,
        currentGender,
      }}
    >
      {checkResponse !== null ? (
        <div>
          <Router>
            {footerShow && <Navbar />} {isAuthenticated && <Checkonline />}{" "}
            {isAuthenticated && myown_data !== null && footerShow && (
              <Sidebar />
            )}
            <>
              {!isAuthenticated ? (
                <Routes>
                  <Route path="/" element={<Register />} />
                  <Route path="/:language" element={<SiteStartLanguage />} />
                  <Route
                    path="/chat/:status"
                    element={isAuthenticated ? <Chat /> : <Loader red={true} />}
                  />
                  <Route
                    path="/chat/:status/:inboxid"
                    element={isAuthenticated ? <Chat /> : <Loader red={true} />}
                  />
                  <Route
                    path="/confirm-your-email"
                    element={<Confirmemail />}
                  />
                  <Route
                    path="/email-confirmation"
                    element={<ConfirmEmailMsg />}
                  />
                  <Route
                    path="/forgot-password-message"
                    element={<Forgotpassmsg />}
                  />
                  <Route
                    path="/confirmemail/:userId/:token"
                    element={<VerifyConfirmEmail />}
                  />
                  <Route path="/contact-receipt" element={<ContactReceipt />} />
                  <Route
                    path="/resetpassword/:userId/:token"
                    element={<Resetpassword />}
                  />
                  <Route
                    path="/successStories"
                    element={<SuccessStoryPage />}
                  />
                  <Route path="/public-page/:page" element={<PublicPage />} />
                  <Route path="/blogs" element={<BlogList />} />
                  <Route path="/blog/:siteid" element={<Blog />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/public-contact" element={<PublicContact />} />
                  <Route
                    path="/search/:page/:gender"
                    element={<Loader red={true} />}
                  />
                  <Route path="/search/:page" element={<Loader red={true} />} />
                  <Route
                    path="/activity/:page"
                    element={<Loader red={true} />}
                  />
                  <Route
                    path="/matches/:page"
                    element={<Loader red={true} />}
                  />
                  <Route
                    path="/profile/:profileid"
                    element={
                      isAuthenticated ? (
                        <PublicProfile />
                      ) : (
                        <Loader red={true} />
                      )
                    }
                  />
                  <Route
                    path="/profile-settings/view"
                    element={<Loader red={true} />}
                  />
                  <Route
                    path="/profile-settings/edit"
                    element={<Loader red={true} />}
                  />
                  <Route
                    path="/profile-settings/delete"
                    element={<Loader red={true} />}
                  />
                  <Route
                    path="/profile-settings/photos"
                    element={<Loader red={true} />}
                  />
                  <Route
                    path="/profile-settings/image-access"
                    element={<Loader red={true} />}
                  />
                  <Route
                    path="/change-email-receipt"
                    element={<Loader red={true} />}
                  />
                  <Route
                    path="/profile-settings/agent"
                    element={<Loader red={true} />} //
                  />
                  <Route path="/payment" element={<Loader red={true} />} />
                  <Route
                    path="/payment/cancel"
                    element={<Loader red={true} />}
                  />
                  <Route
                    path="/memberssettings/:page"
                    element={<Loader red={true} />}
                  />
                  <Route path="/admin/:page" element={<Loader red={true} />} />
                  <Route
                    path="/changeemailconfirm/:userid/:email/:token"
                    element={<Loader red={true} />}
                  />
                  <Route
                    path="/confirmemail/:userId/:token"
                    element={<Loader red={true} />}
                  />
                  <Route
                    path="/payment-success"
                    element={<Loader red={true} />}
                  />
                  <Route
                    path="/payment-wrong"
                    element={<Loader red={true} />}
                  />
                  <Route
                    path="/profile-notfound"
                    element={<Loader red={true} />}
                  />
                  <Route
                    path="/too-many-requests"
                    element={<TooManyRequests />}
                  />
                  {/* <Route path="/error" element={<Loader red={true} />} /> */}
                  <Route path="/error" element={<SomethingWrong />} />
                  <Route
                    path="/contact-receipt"
                    element={<Loader red={true} />}
                  />
                  <Route path="/dashboard" element={<Loader red={true} />} />
                  <Route path="/welcomepage" element={<Loader red={true} />} />
                  <Route path="/login" element={<Loader red={true} />} />
                  <Route
                    path="/successStories"
                    element={<Loader red={true} />}
                  />
                  {/* <Route path="/blogs" element={<Loader red={true} />} /> */}
                  {/* <Route path="/blog/:siteid" element={<Loader red={true} />} /> */}
                  {/* <Route
                    path="/public-page/:page"
                    element={<Loader red={true} />}
                  /> */}
                  <Route component={Notfound} />
                  <Route path="*" element={<Notfound />} />
                </Routes>
              ) : (
                <div className=" w-full bg-blue-50">
                  {" "}
                  {myown_data !== null ? (
                    <Routes>
                      <Route
                        exact
                        path="/chat/:status"
                        element={
                          isAuthenticated ? (
                            <ErrorBoundary>
                              <Chat />
                            </ErrorBoundary>
                          ) : (
                            <Loader red={true} />
                          )
                        }
                      />
                      <Route
                        path="/chat/:status/:inboxid"
                        element={
                          isAuthenticated ? (
                            <ErrorBoundary>
                              <Chat />
                            </ErrorBoundary>
                          ) : (
                            <Loader red={true} />
                          )
                        }
                      />
                      <Route
                        exact
                        path="/chat"
                        element={
                          isAuthenticated ? (
                            <ErrorBoundary>
                              <Navigate to="/chat/active" />
                            </ErrorBoundary>
                          ) : (
                            <Loader red={true} />
                          )
                        }
                      />
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/welcomepage" element={<WelcomePage />} />
                      <Route exact path="/" element={<StartPage />} />
                      <Route path="/login" element={<Loader red={true} />} />
                      <Route
                        path="/search/:page/:gender"
                        element={<Search />}
                      />
                      <Route path="/search/:page" element={<Search />} />
                      <Route path="/activity/:page" element={<Activity />} />
                      <Route path="/matches/:page" element={<Matches />} />
                      <Route
                        path="/profile/:profileid"
                        element={<PublicProfile />}
                      />
                      <Route
                        path="/profile-settings/view"
                        element={<PrivateProfile />}
                      />
                      <Route path="/faq" element={<FAQ />} />
                      <Route
                        path="/public-contact"
                        element={<PrivateContact />}
                      />
                      <Route
                        path="/profile-settings/edit"
                        element={<ProfileEdit />}
                      />
                      <Route
                        path="/profile-settings/delete"
                        element={<ProfileDelete />}
                      />
                      <Route
                        path="/profile-settings/photos"
                        element={<ProfilePhotos />}
                      />
                      <Route
                        path="/profile-settings/image-access"
                        element={<ImageAccess />}
                      />
                      <Route path="/payment" element={<Payment />} />
                      <Route path="/makepayment" element={<PaymentCheck />} />
                      <Route
                        path="/payment/cancel"
                        element={<PaymentCancel />}
                      />
                      <Route
                        path="/memberssettings/:page"
                        element={<MembersSettings />}
                      />
                      <Route path="/admin/:page" element={<Admin />} />
                      <Route
                        path="/changeemailconfirm/:userid/:email/:token"
                        element={<ChangeEmailChange />}
                      />
                      <Route
                        path="/confirmemail/:userId/:token"
                        element={<VerifyConfirmEmail />}
                      />
                      <Route
                        path="/payment-success"
                        element={<PaymentSuccess />}
                      />
                      <Route path="/payment-wrong" element={<PaymentWrong />} />
                      <Route
                        path="/too-many-requests"
                        element={<TooManyRequests />}
                      />
                      <Route path="/error" element={<SomethingWrong />} />
                      <Route path="/404" element={<Notfound />} />
                      <Route
                        path="/profile-notfound"
                        element={<ProfileNotFound />}
                      />
                      <Route
                        path="/contact-receipt"
                        element={<ContactReceipt />}
                      />
                      <Route
                        path="/change-email-receipt"
                        element={<ChangeEmailReceipt />}
                      />
                      <Route
                        path="/change-password-receipt"
                        element={<ChagePassReceipt />}
                      />
                      <Route
                        path="/successStories"
                        element={<SuccessStoryPage />}
                      />
                      <Route path="/blogs" element={<BlogList />} />
                      <Route path="/blog/:siteid" element={<Blog />} />
                      <Route
                        path="/public-page/:page"
                        element={<PublicPage />}
                      />
                      <Route component={Notfound} />
                      <Route path="*" element={<Notfound />} />
                    </Routes>
                  ) : (
                    <Spinner />
                  )}{" "}
                </div>
              )}{" "}
            </>{" "}
            {show && <GDPR />}
            {footerShow && <Footer />}{" "}
          </Router>{" "}
        </div>
      ) : (
        <Spinner />
      )}{" "}
    </MainContent.Provider>
  );
}

export default App;

import { useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { MainContent } from "../App";

const SiteStartLanguage = () => {
  const { language } = useParams();
  const navigate = useNavigate();

  const { changeDirection, changeDirectionsame } = useContext(MainContent);
  useEffect(() => {
    if (language) {
      const supportedLanguages = [
        "en",
        "da",
        "de",
        "fr",
        "it",
        "es",
        "nl",
        "no",
        "sv",
        "tr",
        "ar",
      ];

      if (supportedLanguages.includes(language)) {
        if (language !== "ar") {
          changeDirectionsame(language);
        } else {
          changeDirection(language);
        }
        localStorage.setItem("i18nextLng", language);
      } else {
        changeDirectionsame(language);
        localStorage.setItem("i18nextLng", "en"); // Fallback to English
      }
    } else {
      changeDirectionsame(language);
      localStorage.setItem("i18nextLng", "en"); // Default to English if no language is set
    }

    navigate("/"); // Redirect to default route using navigate()
  }, [language, navigate]); // Dependency array triggers when language changes

  return null; // No UI rendering, just a redirect and language handling.
};

export default SiteStartLanguage;
